import './App.css';

import React, {useState, useEffect} from 'react'
import { useIdleTimer } from 'react-idle-timer'

const UmasCoreApp = ({config}) => {

	const [timedOut, setTimedOut] = useState(false);
	const [heartbeat, setHeartbeat] = useState(0); 
	const [sendHeartbeatRequests, setSendHeartbeatRequests] = useState(config.logged_in);

	const goHome = (event) => {
		event.preventDefault();
		if(event.target.className === 'timeout-overlay' || event.target.type === 'button'){
			window.location.href = config.umas_url + '/massTemplate.php';
		}
	}

	const sendHeartbeatRequest = (action) => {
	
		if(!sendHeartbeatRequests){
			return;
		}
		
		const url = config.umas_url + '/core-api/?heartbeat';
		const payload = {
			method: 'POST',
			mode: config.dev ? 'cors' : 'same-origin',
			cache: 'no-cache',
			credentials: config.dev ? 'include' : 'same-origin',
			headers: { 
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({action: action})
		}

		fetch(url, payload)
		.then(response =>
			response.json().then(json => {
				if (!response.ok) {
					console.log('Error response', json);
					return Promise.reject(json)
				}
				setHeartbeat(json.timestamp);
				
			}).catch((err) => { 
				console.log('JSON error', err);
				
				const contentType = response.headers.get('content-type');
				let msg = response.statusText;
				if (contentType && contentType.includes('application/json') && err.message) {
					msg = err.message;
				} 
				return Promise.reject({message: msg +  ' (error code: ' + response.status + ')'  }) 
			})
		).catch((err) => {
			console.log('Fetch error', err);
		})
	}
	
	const onAction = (event: Event, idleTimer: IIdleTimer) => {
		if(Date.now()/1000 - heartbeat > 60){
			sendHeartbeatRequest('set');
		} 
	};
	
	const onIdle = () => {
		console.log('Timed out...');
		
		setTimedOut(true);
		sendHeartbeatRequest('logout');
		setSendHeartbeatRequests(false);
	}
	
	const idleTimer = useIdleTimer({ 
		disabled: (!config.logged_in || config.timeout === 0),
		timeout: 1000 * 60 * config.timeout,
		stopOnIdle: true,
		crossTab: true,
		syncTimers: true, 
		debounce: 1000,
		// throttle: 500,
		// promptBeforeIdle: 1000 * 30,
  		onAction,
		// onActive,
		// onPrompt,
		onIdle,
	})
	
	// Send a request to keep the session alive every 15 min
	useEffect(() => {
		const keepSession = setInterval(() => {
			sendHeartbeatRequest('session');
		}, 1000 * 60 * 15);
		return () => clearInterval(keepSession);
	}, []);
	
	return timedOut ? 
		<div className="timeout-overlay" onClick={goHome}>
		<div className="timeout">
			<p><strong>Logged Out</strong></p>
			<p>You have been logged out of U-MAS due to inactivity.</p>
			<button type="button" onClick={goHome}>OK</button>
		</div>
		</div> : null
		

}

export default UmasCoreApp;
