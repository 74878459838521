import React from 'react';
import ReactDOM from 'react-dom/client';
import UmasCoreApp from './App';

const root = ReactDOM.createRoot(document.getElementById('umas-core-react'));
root.render(
  <React.StrictMode>
    <UmasCoreApp config = { window.$umas_core_config } />
  </React.StrictMode>
);

